import styles from "../Styles/Navbar.module.css";
const Footer = () => {
  return (
    <div className="lg:mt-12 lg:mb-12 md:mt-12 md:mb-12 grid grid-cols-1 items-center  lg:grid-cols-2 md:grid-cols-2 gap-y-3 top-1/2">
      <div className="text-center block justify-center items-center">
        <img
          className="w-1/3 h-auto mx-auto block"
          width={700}
          src={"/Images/Logo.png"}
          alt="Logo"
        />
        <br />
        <p className={`${styles.ContactFont2}`}>
          1621 Daisy Corner Dr, Prosper TX 75078
        </p>
      </div>
      <div className="text-center mx-30 my-5">
        <h2 className={`${styles.ContactFont1} `}> Contact Us</h2>

        <p className={`${styles.ContactFont2} `}>
          Call us for any question you may have
        </p>
        <p className={`${styles.ContactFont3}`}> +1 510-458-6059</p>
        <p className={`${styles.ContactFont3}`}> +1 724-467-3839</p>
        <br />
        <p className={`${styles.ContactFont2} `}>Email us for support</p>
        <p className={`${styles.ContactFont3}`}> alankritdecors2@gmail.com</p>
      </div>
    </div>
  );
};
export default Footer;
