import BookYourEvent from "../Components/BookYourEvent";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import styles from "../Styles/Navbar.module.css";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <Link to="/">
        <div className="flex  justify-center items-center ">
          <img
            className="w-1/4 h-auto"
            width={700}
            src={"/Images/Logo.png"}
            alt="Logo"
          />
        </div>
      </Link>
      <div></div>
      <div class="mt-10 mb-12 grid grid-flow-row-dense lg:grid-cols-2 md:grid-cols-2 grid-cols-1 ">
        {" "}
        <div class="">
          <h2 className={`${styles.aboutdecor} `}>
            Introducing
            <br /> Alankrit Decors!
          </h2>
          <br />
          <p className={`${styles.paradecor} `}>
            <span className=" ">
              At Alankrit Decors, we firmly believe in the mantra
            </span>
            <br />
            <span className="font-extrabold italic text-pink-400">
              {" "}
              "Your Imagination is Our Creation."
            </span>{" "}
            <br />
            <br />
            Our talented team is dedicated to providing a meticulous and
            artistic touch to bring your dream event to life. We offer a wide
            range of party decoration services at highly competitive prices,
            catering to various occasions such as Housewarming, Birthdays,
            Gender Reveals, Baby Showers, Cradle ceremonies, Half Saree
            functions, and any traditional decoration events you can envision.
            Additionally, we provide rental services for all housewarming and
            birthday decoration items. <br />
            <br /> With our signature blend of modern elegance and high-concept
            glamour, we strive to elevate every experience we design. Our team
            is committed to leaving a lasting impression on your guests,
            ensuring that your event is remembered fondly for years to come. We
            eagerly anticipate the opportunity to collaborate with you in
            bringing your dream event to fruition!
            <br />
            <br /> For the past two years, we have proudly served the
            communities of Irving, Plano, Frisco, Carrollton, Arlington,
            Prosper, Celina, and Argyle with our exceptional decoration event
            services. As official partners with Décor by Krishna, we guarantee
            top-notch quality and professionalism in every aspect of our work.
          </p>
        </div>
        <div className=" flex text-center justify-center items-center">
          <img style={{ width: "60%" }} src={"/Images/decor1.jpg"} />
        </div>
      </div>
      <BookYourEvent />
      <Footer />
    </>
  );
};
export default AboutUs;
