import BookYourEvent from "../Components/BookYourEvent";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import styles from "../Styles/Navbar.module.css";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Image } from "antd";
import { app } from "../firebase";
import { Link } from "react-router-dom";
import { Spin, Flex } from "antd";
const Gallery = () => {
  const [itemData, setItemData] = useState([]);
  const storage = getStorage(app);
  const [loading, setLoading] = useState(true);
  // Create a reference under which you want to list
  const listRef = ref(storage, "");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await listAll(listRef);
        const imageUrls = [];
        for (const itemRef of res.items) {
          const url = await getDownloadURL(ref(storage, itemRef.fullPath));
          imageUrls.push(url);
        }
        setItemData(imageUrls);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <Link to="/">
        <div className="flex justify-center items-center ">
          <img
            className="w-1/4 h-auto"
            width={700}
            src={"/Images/Logo.png"}
            alt="Logo"
          />
        </div>
      </Link>
      <div className="text-center">
        <h1 className={`${styles.GalleryFont}`}>
          Roll through our library of event designs
        </h1>
      </div>

      {loading && (
        <div className="text-center justify-center flex">
          <Flex align="center" gap="middle">
            <Spin size="large" style={{ color: "#fde2e1" }} fullscreen />
          </Flex>
        </div>
      )}
      {!loading && (
        <Box
          className="lg:mt-4 lg:mb-4 lg:ml-10 lg:mr-10 md:mt-4 md:mb-4 md:ml-10 md:mr-10 mx-4 my-3"
          sx={{
            width: window.width,

            overflowY: "scroll",
          }}
        >
          <ImageList variant="masonry" cols={3} gap={8}>
            {itemData.map((item) => (
              <ImageListItem key={item}>
                <Image
                  srcSet={`${item}`}
                  src={`${item}`}
                  alt=""
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
      <BookYourEvent />
      <Footer />
    </>
  );
};
export default Gallery;
