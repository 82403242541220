import { useEffect, useState } from "react";
import BookYourEvent from "../Components/BookYourEvent";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import styles from "../Styles/Navbar.module.css";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { Flex, Spin } from "antd";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Select,
  DatePicker,
} from "antd";
const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    emailjs.init("e145RhrYdoKIV5FpW");
  }, []);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setLoading(true);

    try {
      await emailjs.send("service_plhtzc9", "template_n55ckik", values);
      message.success(
        "Successfully Submitted, We will contact you shortly😊!",
        3
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    console.log("Success", values);
  };
  const onFinishFailed = (values) => {
    console.log("Errors is", values);
    message.error("Submit failed!");
  };
  return (
    <>
      <Navbar />
      {loading && (
        <div className="text-center justify-center flex">
          <Flex align="center" gap="middle">
            <Spin size="large" style={{ color: "#fde2e1" }} fullscreen />
          </Flex>
        </div>
      )}
      <Link to="/">
        <div className="flex justify-center items-center ">
          <img
            className="w-1/4 h-auto"
            width={700}
            src={"/Images/Logo.png"}
            alt="Logo"
          />
        </div>
      </Link>
      <div className="text-center mt-10">
        <h1 className={`${styles.servicetext}`}>Feel Free to Contact Us</h1>
      </div>
      <Form
        name="contact"
        className="mx-16 lg:mx-32 md:mx-32"
        form={form}
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="Name"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>Name</label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "string",
              min: 2,
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          name="Email"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Email
            </label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="PhoneNumber"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Phone Number
            </label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "number",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} placeholder="Phone Number" />
        </Form.Item>
        <Form.Item
          name="Message"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Message
            </label>
          }
          rules={[
            {
              type: "string",
            },
          ]}
        >
          <Input.TextArea placeholder="Message" />
        </Form.Item>
        <Form.Item className="mt-4" style={{ width: "100%" }}>
          <Button
            block
            type="primary"
            style={{ background: "#f9a8d4" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <BookYourEvent />
      <Footer />
    </>
  );
};
export default ContactUs;
