import { useEffect, useState } from "react";
import BookYourEvent from "../Components/BookYourEvent";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import styles from "../Styles/Navbar.module.css";
import emailjs from "@emailjs/browser";
import { Flex, Spin } from "antd";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Select,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";

function gfg_Run(inpdate) {
  let date = new Date(inpdate); // Convert inpdate to a Date object

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Convert the date to UTC to make it timezone independent
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );

  // Extract components and format the date
  let nDate =
    (date.getUTCMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getUTCDate().toString().padStart(2, "0") +
    "/" +
    date.getUTCFullYear();

  console.log(nDate); // Log the date in ISO format for debugging

  return nDate;
}

const Inquiry = () => {
  const dateFormat = "MM/DD/YYYY";
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    emailjs.init("e145RhrYdoKIV5FpW");
  }, []);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setLoading(true);
    values["PhoneNumber"] = values["PhoneNumber"].toString();
    values["DecorBudget"] = values["DecorBudget"].toString();
    values["EventDate"] = gfg_Run(values["EventDate"]);

    try {
      await emailjs.send("service_plhtzc9", "template_ngdwd8l", values);
      console.log(values);
      message.success(
        "Successfully Submitted, We will contact you shortly😊!",
        3
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    console.log("Success", values);
  };
  const onFinishFailed = (values) => {
    console.log("Errors is", values);
    message.error("Submit failed!");
  };

  return (
    <>
      {loading && (
        <div className="text-center justify-center flex">
          <Flex align="center" gap="middle">
            <Spin size="large" style={{ color: "#fde2e1" }} fullscreen />
          </Flex>
        </div>
      )}
      <Navbar />
      <Link to="/">
        <div className="flex justify-center items-center ">
          <img
            className="w-1/4 h-auto"
            width={700}
            src={"/Images/Logo.png"}
            alt="Logo"
          />
        </div>
      </Link>
      <div className="text-center mt-10">
        <h1 className={`${styles.servicetext}`}>Inquiry Form</h1>
      </div>
      <Form
        name="inquiry"
        className="mx-16 lg:mx-32 md:mx-32"
        form={form}
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="Name"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>Name</label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "string",
              min: 2,
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          name="Email"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Email
            </label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="PhoneNumber"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Phone Number
            </label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "number",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} placeholder="Phone Number" />
        </Form.Item>
        <Form.Item
          name="TypeOfEvent"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Type Of Event
            </label>
          }
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select type of event">
            <Select.Option value="Housewarming Decorations">
              Housewarming Decorations
            </Select.Option>
            <Select.Option value="Birthday Decorations">
              Birthday Decorations
            </Select.Option>
            <Select.Option value="Gender Reveal Decorations">
              Gender Reveal Decorations
            </Select.Option>
            <Select.Option value="Baby Shower Decorations">
              Baby Shower Decorations
            </Select.Option>
            <Select.Option value="Cradle Ceremony">
              Cradle Ceremony
            </Select.Option>
            <Select.Option value="Half Saree functions">
              Half Saree functions
            </Select.Option>
            <Select.Option value="Dhoti Function">Dhoti Function</Select.Option>

            <Select.Option value="Engagement">Engagement</Select.Option>
            <Select.Option value="Mangalasnanam">Mangalasnanam</Select.Option>
            <Select.Option value="Pellikooturu & Pellikoduku">
              Pellikooturu & Pellikoduku (Haldi)
            </Select.Option>
            <Select.Option value="Mehendi">Mehendi</Select.Option>
            <Select.Option value="Wedding">Wedding</Select.Option>
            <Select.Option value="Satyanarayanaswamy vratam">
              Satyanarayanaswamy vratam
            </Select.Option>
            <Select.Option value="Others">Others</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="EventDate"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Event Date
            </label>
          }
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} format={dateFormat} />
        </Form.Item>
        <Form.Item
          name="EventVenue"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Event Venue
            </label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "string",
            },
          ]}
        >
          <Input placeholder="Event Venue" />
        </Form.Item>
        <Form.Item
          name="DecorBudget"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Event Estimated Budget
            </label>
          }
          rules={[
            {
              required: true,
            },
            {
              type: "number",
            },
          ]}
        >
          <InputNumber
            prefix="$"
            style={{ width: "100%" }}
            placeholder="Enter Budget"
          />
        </Form.Item>
        <Form.Item
          name="Vision"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              Tell us about your vision / wish list
            </label>
          }
          rules={[
            {
              type: "string",
            },
          ]}
        >
          <Input.TextArea placeholder="Tell us about your vision" />
        </Form.Item>
        <Form.Item
          name="Source"
          label={
            <label style={{ color: "#f472b6", fontWeight: "bold" }}>
              How did you hear about us?
            </label>
          }
          rules={[]}
        >
          <Select placeholder="Select an option">
            <Select.Option value="Friend & Family">
              Friends & Family
            </Select.Option>
            <Select.Option value="Online Search">Online Search</Select.Option>
            <Select.Option value="Social Media">Social Media</Select.Option>
            <Select.Option value="Advertisement">Advertisement</Select.Option>

            <Select.Option value="Event or Conference">
              Event or Conference
            </Select.Option>
            <Select.Option value="Print Media">Print Media</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>
        <label
          style={{
            color: "#f472b6",
            fontWeight: "bold",
          }}
        >
          Please email your inspiration pictures to{" "}
          <a
            href={`mailto:alankritdecors2@gmail.com?subject=Inspiration images`}
            target="_blank"
            style={{
              color: "#f472b6",
              fontWeight: "bold",
            }}
          >
            alankritdecors2@gmail.com
          </a>
        </label>
        <Form.Item className="mt-4" style={{ width: "100%" }}>
          <Button
            block
            type="primary"
            style={{ background: "#f9a8d4" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <BookYourEvent />
      <Footer />
    </>
  );
};
export default Inquiry;
