const ComingSoon = () => {
  return (
    <>
      <div className="h-screen text-center items-center justify-center h-full w-full absolute flex bg-red-100">
        <img
          className="text-center items-center justify-center top-1/2 "
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYLT-zm-pm1ZxdAD50i2ZEz_C9ivfF15bmhQ&s"
        ></img>
      </div>
    </>
  );
};
export default ComingSoon;
