import styles from "../Styles/Navbar.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };
  return (
    <div className="App ">
      <nav className={`${styles.navbar}`}>
        <a href="#home" className={`${styles.logo}`}></a>
        <ul className={`${styles.navMenu} ${isActive ? styles.active : ""}`}>
          <Link to="/">
            <li onClick={removeActive}>
              <a href="" className={`${styles.navLink}`}>
                Home
              </a>
            </li>
          </Link>
          <Link to="/services">
            <li onClick={removeActive}>
              <a href="" className={`${styles.navLink}`}>
                Services
              </a>
            </li>
          </Link>
          <Link to="/gallery">
            <li onClick={removeActive}>
              <a href="" className={`${styles.navLink}`}>
                Gallery
              </a>
            </li>
          </Link>
          <Link to="/about-us">
            <li onClick={removeActive}>
              <a href="" className={`${styles.navLink}`}>
                About Us
              </a>
            </li>
          </Link>
          <Link to="/contact">
            <li onClick={removeActive}>
              <a href="" className={`${styles.navLink}`}>
                Contact
              </a>
            </li>
          </Link>
        </ul>
        <div
          className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
          onClick={toggleActiveClass}
        >
          <span className={`${styles.bar}`}></span>
          <span className={`${styles.bar}`}></span>
          <span className={`${styles.bar}`}></span>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
