import logo from "./logo.svg";
import "./App.css";
import HomePage from "./Pages/HomePage";
import { Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import Gallery from "./Pages/Gallery";
import Service from "./Pages/Service";
import ComingSoon from "./Pages/ComingSoon";
import Inquiry from "./Pages/Inquiry";
import ContactUs from "./Pages/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage></HomePage>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Service />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
