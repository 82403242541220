import styles from "../Styles/Navbar.module.css";
import { Link } from "react-router-dom";
const BookYourEvent = () => {
  return (
    <div
      className="grid grid-cols-1 items-center  lg:grid-cols-2 md:grid-cols-2 gap-y-3 top-1/2"
      style={{ backgroundColor: "#f472b6" }}
    >
      <div className="text-center">
        <h4 className={`${styles.BookNowFont} `}>Book your event!</h4>
      </div>
      <div className="text-center items-center">
        <Link to="/inquiry">
          <button
            type="button"
            class="text-black mt-3 items-center mx-auto my-auto bg-pink-100 hover:bg-pink-50 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
          >
            Book Now
          </button>
        </Link>
      </div>
    </div>
  );
};
export default BookYourEvent;
