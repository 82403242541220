import BookYourEvent from "../Components/BookYourEvent";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import styles from "../Styles/Navbar.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../Styles/Carousel.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
const Service = () => {
  const CustomArrowPrev = ({ onClick, hasPrev, label }) => (
    <button
      className="custom-arrow custom-arrow-prev"
      onClick={onClick}
      disabled={!hasPrev}
      aria-label={label}
      style={{ color: "black" }} // Set arrow color to black
    >
      Prev
    </button>
  );

  // Custom arrow component for next arrow
  const CustomArrowNext = ({ onClick, hasNext, label }) => (
    <button
      className="custom-arrow custom-arrow-next"
      onClick={onClick}
      disabled={!hasNext}
      aria-label={label}
      style={{ color: "black" }} // Set arrow color to black
    >
      Next
    </button>
  );
  return (
    <>
      <Navbar />
      <Link to="/">
        <div className="flex justify-center items-center ">
          <img
            className="w-1/4 h-auto"
            width={700}
            src={"/Images/Logo.png"}
            alt="Logo"
          />
        </div>
      </Link>
      <div className="text-center mt-10">
        <h1 className={`${styles.servicetext}`}>We offer</h1>
      </div>
      <Carousel
        renderArrowPrev={(clickHandler, hasPrev, label) => (
          <span className="arrow-left" onClick={clickHandler}>
            <span className="icon-keyboard_arrow_left">&#8592;</span>
          </span>
        )}
        renderArrowNext={(clickHandler, hasNext, label) => (
          <span className="arrow-right" onClick={clickHandler}>
            <span className="icon-keyboard_arrow_right">&#8594;</span>
          </span>
        )}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        swipeable={true}
        showThumbs={false}
        centerMode={false}
        dynamicHeight={false}
        showArrows={true}
        infiniteLoop={true}
      >
        <div className="">
          <img
            className={`${styles.carouselimage}`}
            height={300} // Set the height to a fixed value, e.g., 300 pixels
            src="https://firebasestorage.googleapis.com/v0/b/alankrit-decors.appspot.com/o/20230721_224816.jpg?alt=media&token=362afaaf-cfc6-4cfc-a446-a62b6caf2e7f"
          />
        </div>
        <div className="">
          <img
            className={`${styles.carouselimage}`}
            height={300} // Set the height to a fixed value, e.g., 300 pixels
            src="https://firebasestorage.googleapis.com/v0/b/alankrit-decors.appspot.com/o/20230908_184114.jpg?alt=media&token=1801d465-ec29-4937-8d57-2e4557167da3"
          />
        </div>
        <div className="">
          <img
            className={`${styles.carouselimage}`}
            height={300} // Set the height to a fixed value, e.g., 300 pixels
            src="https://firebasestorage.googleapis.com/v0/b/alankrit-decors.appspot.com/o/20230916_154523.jpg?alt=media&token=bb391997-ccb1-4b72-8596-95bb8a0d2368"
          />
        </div>
      </Carousel>

      <div className="grid mt-5 lg:grid-cols-2 my-6">
        <div className="flex flex-col items-center ">
          <h1 className={`${styles.servicetext}`}>
            Party & Traditional Events
          </h1>
          <ul className="list-disc pl-10 text-left">
            <li className={`${styles.ServiceFont}`}>
              Housewarming Decorations
            </li>
            <li className={`${styles.ServiceFont}`}>Birthday Decorations</li>
            <li className={`${styles.ServiceFont}`}>
              {" "}
              Gender Reveal Decorations
            </li>
            <li className={`${styles.ServiceFont}`}>Baby Shower Decorations</li>
            <li className={`${styles.ServiceFont}`}>Cradle Ceremony</li>
            <li className={`${styles.ServiceFont}`}>Half Saree Functions</li>
            <li className={`${styles.ServiceFont}`}>Dhoti Function</li>
            <li className={`${styles.ServiceFont}`}>
              All kinds of Traditional events
            </li>
          </ul>
        </div>
        <div className="flex flex-col items-center ">
          <h1 className={`${styles.servicetext}`}>Wedding Events</h1>
          <ul className="list-disc pl-10 text-left">
            <li className={`${styles.ServiceFont}`}>Engagement</li>
            <li className={`${styles.ServiceFont}`}>Mangalasnanam</li>
            <li className={`${styles.ServiceFont}`}>
              Pellikooturu & Pellikoduku (Haldi)
            </li>
            <li className={`${styles.ServiceFont}`}>Mehendi</li>
            <li className={`${styles.ServiceFont}`}>Wedding</li>
            <li className={`${styles.ServiceFont}`}>
              Satyanarayanaswamy Vratam
            </li>
          </ul>
        </div>
      </div>

      <BookYourEvent />
      <Footer />
    </>
  );
};
export default Service;
